<template>
  <article>
    <Content>
      <div class="col-12">
        <div class="card-header">
          <div class="alert alert-info" role="alert">店家位置</div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-10 offset-md-1 col-sm-12">
              <div class="mt-3" v-for="(storeGroup, storeGroupIndex) in storeGroupList" :key="storeGroupIndex">
                <div class="badge badge-pill" :class="storeGroupIndex % 2 == 0 ? 'badge-info' : 'badge-success'">
                  {{ storeGroup.group }}
                </div>
                <ul class="list-group list-group-flush mt-1">
                  <li class="list-group-item d-none d-md-block">
                    <div class="row">
                      <div class="col-md-2 col-sm-12">店名</div>
                      <div class="col-md-3 col-sm-12">聯絡電話</div>
                      <div class="col-md-7 col-sm-12">地址</div>
                    </div>
                  </li>
                  <li class="list-group-item" v-for="(item, index) in storeGroup.storeList" :key="index">
                    <div class="row">
                      <div class="col-md-2 col-sm-12">
                        <span style="color: purple">{{ item.storeName }}</span>
                      </div>
                      <div class="col-md-3 col-sm-12"><span class="d-block d-md-none">電話</span>{{ item.storeContact }}</div>
                      <div class="col-md-7 col-sm-12"><span class="d-block d-md-none">地址</span>{{ item.storeAddress }}</div>
                      <img v-if="item.storeLocationImage" :src="item.storeLocationImage" class="store-location-img mt-2">
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Content>
  </article>
</template>

<script>
import Content from "@/components/style/Content"

import "lodash"
export default {
  name: "Location",
  data() {
    return {
      storeGroupList: [],
    }
  },
  components: {
    Content,
  },
  async mounted() {
    try {
      let response = await this.$http.get("/store/searchList?enable=Y")
      if (response.data.errorMsg) {
        return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
      }

      this.storeGroupList = _.map(_.groupBy(response.data, "storeType"), (value, key) => {
        return {
          group: key == "1" ? "氣功墊" : "氣功墊 + 活力機",
          storeList: _.sortBy(value, [
            o => {
              return parseInt(o.sortNo)
            },
          ]),
        }
      })
    } catch (error) {
      return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
    }
  },
}
</script>

<style scoped>
.card-header {
  text-align: center;
  padding: 0;
}
.card-header div {
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: bold;
}
.badge {
  font-size: 1.7rem;
}
.list-group-item {
  font-size: 1.5rem;
}
.store-location-img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
</style>
