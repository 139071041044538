import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"
import Swal from "sweetalert2"
import SocialSharing from "vue-social-sharing"
import VueYoutube from "vue-youtube"
import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap/dist/css/bootstrap.min.css"
import "jquery/dist/jquery.slim.min.js"
import "popper.js/dist/umd/popper.min.js"
import "@/assets/css/themify-icons.css"
import "@/assets/fonts/themify.eot"
import "@/assets/fonts/themify.svg"
import "@/assets/fonts/themify.ttf"
import "@/assets/fonts/themify.woff"
import "@/assets/css/style.css"
import "@/assets/css/responsive.css"
import "@/assets/js/jquery.easing.min.js"
import "@/assets/js/move-top.js"

import App from "@/App"
import router from "@/config/router"
import store from "@/config/store"

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
})
axiosInstance.interceptors.request.use(
  config => {
    config.headers.token = store.getters.getToken()
    store.commit("SET_LOADING", true)
    return config
  },
  error => {
    store.commit("SET_LOADING", false)
    return Promise.reject(error)
  }
)
axiosInstance.interceptors.response.use(
  response => {
    store.commit("SET_LOADING", false)
    return response
  },
  error => {
    store.commit("SET_LOADING", false)
    return Promise.reject(error)
  }
)

let mixin = {
  data() {
    return {
      get paymentTypeList() {
        return [
          //{ label: "匯款", value: "0" },
          { label: "若要信用卡付費，請進下方賴生活圈索取信用卡連結", value: "1" },
          { label: "現金付款", value: "2" },
        ]
      },
      get line_url() {
        return process.env.VUE_APP_LINE_URL
      },
      get liff_id() {
        return process.env.VUE_APP_LIFF_ID
      },
      get liff_image_url() {
        return "https://purpleearthstorage.blob.core.windows.net/image/liff-share001.jpg"
      },
      get line_group_link() {
        return process.env.VUE_APP_LINE_GROUP_LINK
      },
      get constTicketList() {
        return [
          { productNumber: "pd005-10", productName: "2台活力機+氣功墊@1張票", price: 400, use: true },
          { productNumber: "pd005-11", productName: "2台活力機+氣功墊@5張票", price: 1600, use: true },
          { productNumber: "pd005-12", productName: "4台活力機+氣功墊@1張票", price: 500, use: true },
          { productNumber: "pd005-13", productName: "4台活力機+氣功墊@5張票", price: 2000, use: true },
          { productNumber: "pd005-20", productName: "6台活力機+氣功墊@1張票", price: 600, use: true },
          { productNumber: "pd005-21", productName: "6台活力機+氣功墊@5張票", price: 2400, use: true },
          { productNumber: "pd005-16", productName: "8台活力機+氣功墊@1張票", price: 700, use: true },
          { productNumber: "pd005-17", productName: "8台活力機+氣功墊@5張票", price: 2800, use: true },
          { productNumber: "pd005-18", productName: "16台活力機+氣功墊@1張票", price: 1000, use: true },
          { productNumber: "pd005-19", productName: "16台活力機+氣功墊@5張票", price: 4000, use: true },
          { productNumber: "pd005-22", productName: "4/6/8/16台各一+氣功墊@4張票", price: 1580, use: true },

          { productNumber: "pd005-1", productName: "氣功墊@5張票", price: 0, use: false },
          { productNumber: "pd005-2", productName: "2台SPA機@1張票", price: 0, use: false },
          { productNumber: "pd005-3", productName: "2台SPA機@5張票", price: 0, use: false },
          { productNumber: "pd005-4", productName: "4台SPA機@1張票", price: 0, use: false },
          { productNumber: "pd005-5", productName: "4台SPA機@5張票", price: 0, use: false },
          { productNumber: "pd005-6", productName: "6台SPA機@1張票", price: 0, use: false },
          { productNumber: "pd005-7", productName: "6台SPA機@5張票", price: 0, use: false },
          { productNumber: "pd005-8", productName: "8台SPA機@1張票", price: 0, use: false },
          { productNumber: "pd005-9", productName: "8台SPA機@5張票", price: 0, use: false },
          { productNumber: "pd005-14", productName: "5000元VIP:加送10台 (共60台活力機+氣功墊)@60張票", price: 0, use: false },
          { productNumber: "pd005-15", productName: "10000元VIP:加送30台 (共130台活力機+氣功墊)@130張票", price: 0, use: false },

          { productNumber: "pd007", productName: "2台月用@10次", price: 0, use: false },
          { productNumber: "pd008", productName: "(購機)4台月費@吃到飽", price: 0, use: false },
          { productNumber: "pd009", productName: "4台月用@10次", price: 0, use: false },
          { productNumber: "pd010", productName: "(購機)8台月費@吃到飽", price: 0, use: false },
          { productNumber: "pd011", productName: "(一般)4台月費@10次", price: 0, use: false },
          { productNumber: "pd012", productName: "(一般)4台月費@吃到飽", price: 0, use: false },
          { productNumber: "pd013", productName: "8台月用@10次", price: 0, use: false },
          { productNumber: "pd014", productName: "16台月用@10次", price: 0, use: false },
          { productNumber: "pd017", productName: "6台月用@10次", price: 0, use: false },
        ]
      },
      get constMemberItemList() {
        return ["item7", "item9", "item13", "item14", "item15", "item16", "item17"]
      },
      get constMemberList() {
        return ["pd007", "pd009", "pd013", "pd014", "pd015", "pd016", "pd017"]
      },
    }
  },
}

Vue.prototype.$http = axiosInstance
Vue.prototype.$Swal = Swal
Vuex.Store.prototype.$http = axiosInstance
Vuex.Store.prototype.$Swal = Swal
Vuex.Store.prototype.$router = router
Vuex.Store.prototype.$mixin = mixin

Vue.config.productionTip = false

Vue.use(SocialSharing)
Vue.use(VueYoutube)
Vue.mixin(mixin)

new Vue({
  el: "#app",
  router,
  store,
  components: { App },
  template: "<App/>",
})
