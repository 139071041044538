<template>
  <div class="collapse show mt-2">
    <div class="card card-body" style="padding: 0">
      <div class="row">
        <social-sharing :url="myUrl" title="氣能康富" :description="socialDescription" :quote="socialDescription" inline-template network-tag="div">
          <div class="col-sm-6 col-7" style="text-align: right">
            <network network="line" class="d-inline">
              <img src="@/assets/img/icon/line-share.png" style="width: 115px; margin-right: 10px" />
            </network>
          </div>
        </social-sharing>
        <div class="col-sm-6 col-5" style="text-align: left">
          <button type="button" class="btn btn-outline-secondary" style="height: 52px" @click="shareUrl">複製連結</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShareFriend",
  props: ["currentUrl"],
  data() {
    return {
      myUrl: "",
      socialDescription: "",
    }
  },
  methods: {
    shareUrl() {
      navigator.clipboard
        .writeText(`氣能康富\n免費體驗劵\n快享受科技氣能量\n\n${this.myUrl}`)
        .then(() => {
          return this.$Swal.fire({
            icon: "success",
            title: "已複製連結至剪貼簿",
            html: `氣能康富<br>免費體驗劵<br>快享受科技氣能量<br><br><div style="white-space:initial">${this.myUrl}</div>`,
          })
        })
        .catch(err => {
          console.error("Failed to copy: ", err)
        })
    },
  },
  mounted() {
    let parentUser = this.$store.state.userInfo.userProfile.userId
    this.myUrl = `${this.currentUrl}/${parentUser}`
    this.socialDescription = `氣能康富, 免費體驗劵, 快享受科技氣能量\n${this.myUrl}`
  },
}
</script>

<style scoped></style>
