<template>
  <div>
    <!--首圖 start-->
    <section class="hero-section background-img ptb-100">
      <div class="container">
        <div class="row align-items-center justify-content-between py-5">
          <div class="col-md-7 col-lg-6">
            <div class="hero-content-left text-white">
              <h2><span>氣能康富</span> 「地表最強能量養生」</h2>
              <div class="lead">
                <h4>讓你全身細胞微笑</h4>
                <h4>由內而外亮麗</h4>
              </div>
              <h2 style="color: red">身體比去年退步?</h2>
              <div class="lead">
                <h4>老後不失能、現在要預防</h4>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-5 col-lg-5">
            <div class="hero-animation-img">
              <img src="@/assets/img/advertisement/home1.jpg" class="img-fluid" />
            </div>
          </div> -->
        </div>
      </div>
      <div class="bottom-img-absolute">
        <img src="@/assets/img/header-wave.svg" class="img-fluid" />
      </div>
    </section>

    <!--主力商品 start-->
    <!--
    <section class="promo-section ptb-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="section-heading text-center mb-5">
              <h2>三大主力商品</h2>
            </div>
          </div>
        </div>
        <div class="row equal">
          <div class="col-md-4 col-lg-4">
            <div
              class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
            >
              <div class="circle-icon mb-5">
                <span class="ti-pulse text-white"></span>
              </div>
              <h5>頻譜氣功墊</h5>
              <p>螺旋共振科技氣功</p>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <div
              class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
            >
              <div class="circle-icon mb-5">
                <span class="ti-bolt text-white"></span>
              </div>
              <h5>頻譜能量環</h5>
              <p>
                戴上立即有力
              </p>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <div
              class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
            >
              <div class="circle-icon mb-5">
                <span class="text-white">SPA</span>
              </div>
              <h5>頻譜SPA機</h5>
              <p>
                導入氣鈉源素
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
-->
    <!--免費體驗券 start-->
    <section class="about-us ptb-100 background-shape-img">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-7">
            <div class="about-content-left section-heading">
              <h2>
                <router-link to="/advertisement"> 免費體驗券</router-link> <br />
                <span>百聞不如一見！快來體驗～</span>
              </h2>

              <div class="single-feature mb-4 mt-5">
                <div class="icon-box-wrap d-flex align-items-center mb-2">
                  <div class="mr-3">
                    <span class="ti-hand-point-right"></span>
                  </div>
                  <p class="mb-4">氣功墊 ~ 抵三大師(氣功+腳底按摩+經絡推拿)!做氣功墊30分，讓您元氣大增!</p>
                </div>
              </div>
              <div class="single-feature mb-4">
                <div class="icon-box-wrap d-flex align-items-center mb-2">
                  <div class="mr-3">
                    <span class="ti-hand-point-right"></span>
                  </div>
                  <p class="mb-4">活力機 ~ 身體狀況不同，每次泡出顏色不同? 不是單純電解能說明。 體內環保沒乾淨，任何醫療都沒用。</p>
                </div>
              </div>
              <div class="single-feature mb-4">
                <div class="icon-box-wrap d-flex align-items-center mb-2">
                  <div class="mr-3">
                    <span class="ti-hand-point-right"></span>
                  </div>
                  <p class="mb-4">
                    多項專利科技產品。<br />
                    激發身體潛在能量，增強生命活力。<br />
                    市場唯一體驗完，立刻驚奇看到自己身體秘密。<br />
                    健康不能等，養生趁現在。
                  </p>
                </div>
              </div>
              <div style="text-align: center">
                <router-link to="/advertisement" class="btn btn-lg app-store-btn"> 快去了解 </router-link>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-5">
            <div class="about-content-right">
              <img src="@/assets/img/advertisement/home2.jpg" class="img-fluid" />
            </div>
          </div> -->
        </div>
      </div>
    </section>

    <!--免費說明會 start-->
    <!-- <section class="feature-section ptb-100">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-4">
            <div class="download-img">
              <img src="@/assets/img/advertisement/home3.jpg" class="img-fluid" />
            </div>
          </div>
          <div class="col-md-7">
            <div class="feature-contents section-heading">
              <h2>
                免費說明會 <br />
                <span
                  >大家都在找機會，想跟對趨勢<br />
                  如何擁有不斷電印鈔機?</span
                >
              </h2>
              <p>
                你最關心的問題：<br />
                1、頻譜是什麼？<br />
                2、為什麼頻譜對人體產生調理？<br />
                3、如何分享賺錢？<br />
                4、能當事業來經營嗎？
              </p>
              <p>
                快報名免費說明會！
                <router-link to="/advertisement" class="btn btn-lg app-store-btn"> 快去了解 </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!--招分銷商 start-->
    <!-- <section class="background-img">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-7">
            <div class="text-white pb-100" style="padding-top: 20px" id="findSubsidiary">
              <h2 class="text-white">招分銷商及店中店</h2>
              <p class="lead" style="font-weight: 400">
                趨勢在哪裡？ <br />
                1。網際網路 <br />
                2。大健康 <br />
                3。分享經濟 <br /><br />
                最強科技能量養生 <br />
                新型態分銷系統 <br />
                不屯貨、沒有費用，就可以賺錢 <br />
                快快加入──氣能康富大健康商機!
              </p>

              <div class="download-btn">
                <router-link to="/advertisement" class="btn btn-lg app-store-btn"> 快去分享 </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex align-items-end">
              <img src="@/assets/img/advertisement/home4.jpg" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!--熱門商品 start-->
    <section class="our-blog-section ptb-100">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="section-heading mb-5">
              <h2>火紅熱門商品</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="single-blog-card card border-0 shadow-sm">
              <span class="category position-absolute badge badge-pill badge-danger">熱銷中</span>
              <img src="@/assets/img/product/pd1.jpg" class="card-img-top position-relative" />
              <div class="card-body">
                <h3 class="card-title">氣功墊</h3>
                <p class="card-text">螺旋共振科技氣功</p>
                <router-link to="/product/item1" style="color: black"> 快看介紹 <span class="ti-arrow-right"></span> </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="single-blog-card card border-0 shadow-sm">
              <span class="category position-absolute badge badge-pill badge-danger">熱銷中</span>
              <img src="@/assets/img/product/pd2.jpg" class="card-img-top position-relative" />
              <div class="card-body">
                <h3 class="card-title">能量環</h3>
                <p class="card-text">戴上立即有力</p>
                <router-link to="/product/item2" style="color: black"> 快看介紹 <span class="ti-arrow-right"></span> </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="single-blog-card card border-0 shadow-sm">
              <span class="category position-absolute badge badge-pill badge-danger">熱銷中</span>
              <img src="@/assets/img/product/pd3.jpg" class="card-img-top" />
              <div class="card-body">
                <h3 class="card-title">活力機</h3>
                <p class="card-text">個人身體狀況不同，泡出顏色不同</p>
                <router-link to="/product/item3" style="color: black"> 快看介紹 <span class="ti-arrow-right"></span> </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="single-blog-card card border-0 shadow-sm">
              <span class="category position-absolute badge badge-pill badge-primary">促銷中</span>
              <img src="@/assets/img/product/pd5.jpg" class="card-img-top position-relative" />
              <div class="card-body">
                <h3 class="card-title">店內票券</h3>
                <p class="card-text">享受神奇科技養生</p>
                <router-link to="/product/item5" style="color: black"> 快看介紹 <span class="ti-arrow-right"></span> </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import "@/assets/css/animate.min.css"
import "@/assets/css/owl.carousel.min.css"
import "@/assets/css/owl.theme.default.min.css"
import "@/assets/js/owl.carousel.min.js"
import "@/assets/js/scripts.js"
import Button from "@/components/style/Button"

export default {
  name: "Home",
  components: {
    Button,
  },
  async mounted() {
    let errorMsg = new URL(window.location.href).searchParams.get("errorMsg")
    if (errorMsg) {
      let text = ""
      if (errorMsg == "loginCallbackException") {
        text = "Line登入異常，請聯絡系統管理員。"
      } else if (errorMsg == "userNotExists") {
        text = "使用者不存在"
      } else if (errorMsg.startsWith("lineLoginError-")) {
        text = `Line登入異常，錯誤代碼：${errorMsg.split("-")[1]}`
      }

      await this.$Swal.fire({ icon: "error", title: "登入發生錯誤", text })
      window.location.href = window.location.origin
    }
  },
}
</script>

<style scoped>
.hero-content-left h2 {
  color: burlywood;
}
.hero-content-left div {
  padding-left: 3rem;
}
.hero-content-left div h4 {
  color: white;
}
.icon-box-wrap div {
  height: 60px;
}
.icon-box-wrap div span {
  font-size: 2rem;
  color: #9629e6;
}
.single-feature p,
.feature-contents p,
#findSubsidiary p {
  font-size: 1.7rem;
}
.about-content-left h2,
.feature-contents h2,
#findSubsidiary h2 {
  font-size: 2.6rem;
}
.single-blog-card .card-body .card-text,
.single-blog-card .card-body a {
  font-size: 1.7rem;
}
</style>
