<template>
  <header class="header">
    <nav class="navbar navbar-expand-lg fixed-top bg-transparent affix">
      <div class="container">
        <router-link to="/" class="navbar-brand" style="color: #faebd7; font-size: 1.8rem">氣能康富</router-link>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="ti-menu"></span>
        </button>

        <div class="collapse navbar-collapse main-menu" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <!-- <li class="nav-item">
              <router-link to="/" class="nav-link">首頁</router-link>
            </li> -->
            <li class="nav-item">
              <router-link to="/advertisement" class="nav-link">免費體驗券</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/product/item5" class="nav-link">店內票券</router-link>
            </li>
            <!-- 加入會員 -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="javascript:void(0)"
                id="navbarDropdownHome"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                加入會員
              </a>
              <div class="dropdown-menu submenu" aria-labelledby="navbarDropdownHome" id="member-dropdown-item">
                <a class="dropdown-item" @click="goProduct('item7')">2台月用十次</a>
                <a class="dropdown-item" @click="goProduct('item9')">4台月用十次</a>
                <a class="dropdown-item" @click="goProduct('item17')">6台月用十次</a>
                <a class="dropdown-item" @click="goProduct('item13')">8台月用十次</a>
                <a class="dropdown-item" @click="goProduct('item14')">16台月用十次</a>
                <a class="dropdown-item" @click="goProduct('item15')">入會費</a>
                <a class="dropdown-item" @click="goProduct('item16')">請假維持月費</a>
              </div>
            </li>
            <!-- 能量產品 -->
            <li class="nav-item dropdown d-none d-sm-block">
              <a
                class="nav-link dropdown-toggle"
                href="javascript:void(0)"
                id="navbarDropdownHome"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                能量產品
              </a>
              <div class="dropdown-menu submenu" aria-labelledby="navbarDropdownHome">
                <a class="dropdown-item" @click="goProduct('item1')">氣功墊</a>
                <a class="dropdown-item" @click="goProduct('item3')">活力機</a>
                <a class="dropdown-item" @click="goProduct('item2')">能量環</a>
                <a class="dropdown-item" @click="goProduct('item6')">超能量強膝帶</a>
                <a class="dropdown-item" @click="goProduct('item18')">充能寶(能量杯墊)</a>
              </div>
            </li>
            <li class="nav-item d-block d-sm-none">
              <router-link to="/product/item1" class="nav-link">氣功墊</router-link>
            </li>
            <li class="nav-item d-block d-sm-none">
              <router-link to="/product/item3" class="nav-link">活力機</router-link>
            </li>
            <li class="nav-item d-block d-sm-none">
              <router-link to="/product/item2" class="nav-link">能量環</router-link>
            </li>
            <li class="nav-item d-block d-sm-none">
              <router-link to="/product/item6" class="nav-link">超能量強膝帶</router-link>
            </li>
            <li class="nav-item d-block d-sm-none">
              <router-link to="/product/item18" class="nav-link">充能寶(能量杯墊)</router-link>
            </li>
            <li class="nav-item d-none d-sm-block">
              <router-link to="/location" class="nav-link">全國店家</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/cart" class="nav-link">購物車</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/order" class="nav-link">訂單查詢</router-link>
            </li>
            <li class="nav-item d-none d-sm-block">
              <router-link to="/user" class="nav-link">會員中心</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="container d-block d-sm-none">
        <div class="alert d-inline-block only-sm" style="width: 32%">
          <router-link to="/location">全國店家</router-link>
        </div>
        <div class="alert d-inline-block only-sm" style="width: 32%">
          <router-link to="/user">會員中心</router-link>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header",
  methods: {
    goProduct(item) {
      this.$router.push({ path: `/product/${item}` }).catch(err => {})
    },
  },
}
</script>

<style scoped>
.navbar-brand img {
  width: 200px;
}
#member-dropdown-item a {
  padding: 3px 15px;
}
@media (min-width: 320px) and (max-width: 992px) {
  .navbar-brand img {
    width: 175px;
  }
}
.only-sm {
  margin-bottom: 0;
  text-align: center;
  padding: 0;
  font-size: 1.5rem;
  font-weight: bold;
}
.only-sm a {
  color: white;
}
@media (min-width: 992px) {
  .navbar {
    padding: 0 1rem;
  }
  .container {
    max-width: 1250px;
  }
}
</style>
